<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <!-- Cabezera de la pagina principal -->
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Calificaciones</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Operacion</li>
                  <li class="breadcrumb-item active">Procesos</li>
                  <li class="breadcrumb-item active">Calificaciones</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <!-- Cuerpo de la pagina -->
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <!-- Cabecera de la tarjeta -->
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <!-- Div que empuja el boton a la izquierda -->
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        v-on:click="create()"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.calificacion.create'
                          )
                        "
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.cabezera de la tarjeta -->
              <div class="card-body p-0">
                <table
                  id="renderLineas"
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <!-- Nombre item --->
                      <th>
                        Nombre
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.nombre"
                          v-on:keyup="getIndex()"
                        />
                      </th>
                      <!-- ./ Nombre item -->
                      <!-- Fecha inicial -->
                      <th>
                        Fecha Inicial
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_inicial"
                          v-on:keyup="getIndex()"
                        />
                      </th>
                      <!-- ./ Fecha inicial -->
                      <!-- Fecha Final -->
                      <th>
                        Fecha Final
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_final"
                          v-on:keyup="getIndex()"
                        />
                      </th>
                      <!-- ./ Fecha Final -->
                      <!-- Estado -->
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Todos...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <!-- ./ Estado -->
                      <!-- Tamaño del control -->
                      <th style="text-align-last: center">Accion</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in calificaciones.data" :key="item.id">
                      <td>{{ item.nombre }}</td>
                      <td>{{ item.fecha_inicial }}</td>
                      <td>{{ item.fecha_final }}</td>
                      <td v-if="item.estado == 2">
                        <span class="badge badge-success">DEFINITIVO</span>
                      </td>
                      <td v-else>
                        <span class="badge badge-danger">BORRADOR</span>
                      </td>
                      <td style="width: 50px">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            data-toggle="modal"
                            data-target="#Modal_items"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.calificacion.show'
                              )
                            "
                            v-on:click="edit(item)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-on:click="destroy(item.id)"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.calificacion.delete'
                              )
                            "
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="calificaciones.total">
                  <p>
                    Mostrando del <b>{{ calificaciones.from }}</b> al
                    <b>{{ calificaciones.to }}</b> de un total:
                    <b>{{ calificaciones.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="calificaciones"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  name: "Calificaciones",
  components: {
    pagination,
  },
  data() {
    return {
      calificaciones: {},
      /* Encabezados del maestro detalle */
      filtros: {
        nombre: null,
        fecha_inicio: null,
        fecha_fin: null,
        estado: null,
      },
      /* Lista de los select de los formularios */
      listasForms: {
        estados: [],
      },
    };
  },
  methods: {
    /* Metodo que renderiza componente del formulario y variables a transladar */
    create() {
      return this.$router.push({
        name: "/Hidrocarburos/CalificacionesForm",
        params: { accion: "Crear", id: 0 },
      });
    },
    getIndex(page = 1) {
      axios
        .get("/api/hidrocarburos/calificaciones?page=" + page, {
          params: this.filtros /* parametros que se pasan en la ruta */,
        })
        .then((response) => {
          this.calificaciones = response.data;
        });
    },
    save() {
      if (!this.$v.form.$invalid) {
        axios
          .post("/api/admin/itemsInspeccion", this.form)
          .then((response) => {
            document.getElementById("close-modal").click;
            this.form = {};
            this.getIndex(); //Lista de nuevo
            this.$swal({
              icon: "success",
              title: "Se guardo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },
    destroy(idcalificacion) {
      this.$swal({
        title: "Esta seguro de eliminar esta Calificacion?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "No, Cancelar",
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios.delete("/api/hidrocarburos/calificaciones/" + idcalificacion).then(() => {
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se elimino la calificacion de manera exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          });
        }
      });
    },
    /* Lista de estado */
    getEstados() {
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
      });
    },
    edit(calificacion) {
      return this.$router.push({
        name: "/Hidrocarburos/CalificacionesForm",
        params: {
          accion: "Editar",
          data_edit: calificacion,
          id: calificacion.id,
        },
      });
    },
  },

  mounted() {
    this.getEstados();
    this.getIndex();
  },
};
</script>